import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import { apiUrl } from "../config";
import { services } from "../services";
import ipfs from "../config/ipfs";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("x-auth-token", `${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
  getList: (resource, params) => {
    let url = "";
    let query = [];
    query.push(`page=${params.pagination.page}`);

    if (resource === "users") {
      Object.keys(params.filter).map((key) =>
        query.push("roleId=" + params.filter[key])
      );
      query = query.join("&");
      url = `${apiUrl}/user/list?${query}`;
    } else if (resource === "categories") {
      url = `${apiUrl}/category/adminList?list=all`;
    } else if (resource === "nft") {
      url = `${apiUrl}/nft/listNftForAdmin?${query}`;
    } else if (resource === "banners") {
      url = `${apiUrl}/admin/banner/listForAdmin`;
    } else if (resource === "profile-info") {
      url = `${apiUrl}/admin/profile-info/listForAdmin`;
    } else if (resource === "info") {
      url = `${apiUrl}/admin/info/listForAdmin`;
    } else if (resource === "hall-frame-info") {
      url = `${apiUrl}/admin/hall-frame-info/listForAdmin`;
    } else if (resource === "collections") {
      url = `${apiUrl}/nft/listCollectionForAdmin?${query}`;
    } else if (resource === "popular-nft") {
      url = `${apiUrl}/admin/popular/listForAdmin`;
    } else if (resource === "popular-collection") {
      url = `${apiUrl}/admin/popularCollection/listForAdmin`;
    }

    // const { page, perPage } = params.pagination;
    // const { field, order } = params.sort;
    // const query = {
    //     sort: JSON.stringify([field, order]),
    //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    //     filter: JSON.stringify(params.filter),
    // };
    // const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then((response) => ({
      data: response.json.data,
      total: parseInt(response.json.pagination?.totalRecords),
    }));
  },

  getOne: (resource, params) => {
    let url = "";
    if (resource === "users") {
      url = `${apiUrl}/user/userDetails?userId=${params.id}`;
    } else if (resource === "categories") {
      url = `${apiUrl}/category/categoryDetails?categoryId=${params.id}`;
    } else if (resource === "nft") {
      url = `${apiUrl}/nft/single/${params.id}`;
    } else if (resource === "banners") {
      url = `${apiUrl}/admin/banner/details/${params.id}`;
    } else if (resource === "profile-info") {
      url = `${apiUrl}/admin/profile-info/details/${params.id}`;
    } else if (resource === "info") {
      url = `${apiUrl}/admin/info/details/${params.id}`;
    } else if (resource === "hall-frame-info") {
      url = `${apiUrl}/admin/hall-frame-info/details/${params.id}`;
    } else if (resource === "collections") {
      url = `${apiUrl}/nft/collection/${params.id}`;
    } else if (resource === "nft") {
      url = `${apiUrl}/nft/single/${params.id}`;
    } else if (resource === "popular-nft") {
      url = `${apiUrl}/admin/popular/listPopularDetails/${params.id}`;
      return httpClient(url).then(({ json }) => ({
        data: json.data[0],
      }));
    } else if (resource === "popular-collection") {
      url = `${apiUrl}/admin/popularCollection/listPopularDetails/${params.id}`;
      return httpClient(url).then(({ json }) => ({
        data: json.data[0],
      }));
    }

    return httpClient(url).then(({ json }) => ({
      data: json.data,
    }));
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get("content-range").split("/").pop(), 10),
    }));
  },

  update: (resource, params) => {
    let url = "";
    return httpClient(url, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    let url = "";
    if (resource === "nft") {
      url = `${apiUrl}/nft/addNft`;
    }

    return httpClient(url, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: async (resource, params) => {
    let url = "";
    let fileName = "";
    if (resource === "banners") {
      url = `${apiUrl}/admin/banner/delete/${params.id}`;
    } else if (resource === "info") {
      url = `${apiUrl}/admin/info/delete/${params.id}`;
    } else if (resource === "hall-frame-info") {
      url = `${apiUrl}/admin/hall-frame-info/delete/${params.id}`;
    } else if (resource === "profile-info") {
      url = `${apiUrl}/admin/profile-info/delete/${params.id}`;
    } else if (resource === "popular-nft") {
      url = `${apiUrl}/admin/popular/delete/${params.id}`;
    } else if (resource === "popular-collection") {
      url = `${apiUrl}/admin/popularCollection/delete/${params.id}`;
    }

    return httpClient(url, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));
  },

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};

export default dataProvider;
