import { useState, useEffect } from "react";
import {
  SimpleForm,
  TextInput,
  required,
  useRedirect,
  useNotify,
  TopToolbar,
  Toolbar,
  SaveButton,
  SimpleShowLayout,
  SelectArrayInput,
} from "react-admin";
import dataProvider from "../../authentication/dataProvider";
import { actions } from "../../actions";
import { services } from "../../services";
import Loader from "../loading";
import BackButton from "../backButton";

const UserEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [categories, setCategory] = useState([]);
  const [params, setParams] = useState(null);
  const [user, setUser] = useState(null);
  const [approvedCategories, setApprovedCategories] = useState([]);

  useEffect(() => {
    async function getCategory() {
      const categories = await actions.getCategories();
      setCategory(categories.data);
    }
    getCategory();

    async function fetchUser() {
      const response = await dataProvider.getOne("users", { id: props.id });
      setUser(response.data);
    }
    fetchUser();

    async function updateUser() {
      const request = services.put(`user/update/${user.id}`, params);
      request
        .then((response) => {
          setLoading(false); // stop loading.
          if (response.status === 200) {
            notify(response.data.message, `success`);
            redirect("/users");
          }
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
        })
        .catch((e) => {
          setLoading(false); // stop loading.
          if (e.response) {
            if (e.response.status === 401 || e.response.status === 403) {
              notify(`Unauthorized Request`, `error`);
              localStorage.removeItem("token");
              redirect("/users");
            }
            // other error code (404, 500, etc): no need to log out
          } else {
            notify(`Bad request`, `error`);
            redirect("/users");
          }
        });
    }

    if (callAPI) {
      updateUser();
    }
  }, [callAPI]);

  const onSuccess = async (event) => {
    setLoading(true); // start loader
    setParams({ id: user.id, category: event.category, approvedCategories: event.approvedCategories });
    setCallAPI(true);
  };

  const UserActionToolbar = () => (
    <TopToolbar>
      <BackButton color="primary" style={{ marginLeft: "5rem" }} />
    </TopToolbar>
  );

  const UserEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  return loading ? (
    <Loader />
  ) : user ? (
    <>
      <SimpleForm save={onSuccess} toolbar={<UserEditToolbar />}>
        <UserActionToolbar />

        <TextInput disabled label="Id" source="id" initialValue={user.id} />
        <TextInput
          disabled
          label="Name"
          source="name"
          initialValue={user.name}
        />
        <TextInput
          disabled
          label="Email"
          source="email"
          initialValue={user.email}
        />
        <TextInput
          disabled
          label="Username"
          source="username"
          initialValue={user.username}
        />
        <TextInput
          disabled
          label="Role"
          source="role"
          initialValue={user.role.roleName}
        />
        <TextInput
          disabled
          label="Wallet Address"
          source="walletAddress"
          initialValue={user.walletAddress}
        />

        <SimpleShowLayout>
          <SelectArrayInput
            source="category"
            choices={categories}
            defaultValue={user.category
              ?.filter((category) => category)
              .map((category) => category.id)}
            optionValue="id"
            optionText={(category) => `${category.categoryName.en}`}
            validate={required()}
          />
        </SimpleShowLayout>

        <SimpleShowLayout>
          <SelectArrayInput
          style={{width: '100%'}}
            source="approvedCategories"
            choices={categories?.filter((category)=>category.restricted && category.restricted === true ? category : null)}
            defaultValue={user.approvedCategories?.map((category) => category.id)}
            optionValue="id"
            optionText={(category) => `${category.categoryName.en}`}
          />
        </SimpleShowLayout>
      </SimpleForm>
    </>
  ) : (
    ""
  );
};

export default UserEdit;
