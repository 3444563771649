let currentChainID = window.ethereum.networkVersion;

const checkNetwork = async () => {
  if (window.ethereum) {
    const currentChainId = await window.ethereum.request({
      method: "eth_chainId",
    });

    return parseInt(currentChainId, 16);
  }
};

(() => {
  new Promise(function (resolve, reject) {
    resolve(checkNetwork());
  }).then((res) => {
    localStorage.setItem("chainId", res);
  });
})();

const addresses = {
  token: {
    [process.env.REACT_APP_BINANCE_CHAIN_ID]:
      process.env.REACT_APP_BINANCE_TOKEN_ADDRESS,
    [process.env.REACT_APP_ETHEREUM_CHAIN_ID]:
      process.env.REACT_APP_ETHEREUM_TOKEN_ADDRESS,
  },
  escrow: {
    [process.env.REACT_APP_BINANCE_CHAIN_ID]:
      process.env.REACT_APP_BINANCE_ESCROW_ADDRESS,
    [process.env.REACT_APP_ETHEREUM_CHAIN_ID]:
      process.env.REACT_APP_ETHEREUM_ESCROW_ADDRESS,
  },
};

export function getCurrentChainContractAddress(chainName) {
  return addresses[chainName][currentChainID];
}

export function isValidChain(chainId) {
  return [
    process.env.REACT_APP_BINANCE_CHAIN_ID,
    process.env.REACT_APP_ETHEREUM_CHAIN_ID,
  ].includes(chainId);
}

export function isValidChainHex(chainId) {
  return [
    "0x" + parseInt(process.env.REACT_APP_BINANCE_CHAIN_ID).toString(16),
    "0x" + parseInt(process.env.REACT_APP_ETHEREUM_CHAIN_ID).toString(16),
  ].includes(chainId);
}
