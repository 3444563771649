import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  Pagination,
  BooleanField,
  ImageField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { getFileType } from "../../helper/function";
import VideoSquareCover from "../../video-square.jpg";
import AudioSquareCover from "../../audio-square.jpg";

const PopularNFTRowClick = (id, basePath, record) =>
  record.editable ? "edit" : "show";
const PopularNFTShowButton = ({ record }) => (
  <ShowButton basePath="/popular-nft" label="Show" record={record} />
);
const useStyles = makeStyles({
  imgContainer: {
    "& img": {
      height: "60px",
      width: "60px",
      objectFit: "contain",
      // borderRadius: '50%',
    },
  },
});
const PopularNFTPagination = (props) => (
  <Pagination rowsPerPageOptions={[]} {...props} />
);

const CustomRowLayout = ({ record }) => {
  const classes = useStyles();
  const [ext, setExt] = useState(false);

  useEffect(() => {
    function getExtenstion() {
      if (!record.nftId?.image.format) {
        let ext = getFileType(record.nftId?.image.compressed);
        ext.then(function (result) {
          setExt(result);
        });
      } else {
        setExt(record.nftId?.image.format);
      }
    }
    getExtenstion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  if (ext && ext === `image`)
    return (
      <ImageField
        source="nftId.image.original"
        sortable={false}
        label="Image"
        className={classes.imgContainer}
      />
    );
  if (ext && ext === `audio`)
    return (
      <img
        src={AudioSquareCover}
        width="60px"
        height="60px"
        style={{ margin: "10px" }}
      />
    );
  if (ext && ext === `video`)
    return (
      <img
        src={VideoSquareCover}
        width="60px"
        height="60px"
        style={{ margin: "10px" }}
      />
    );
  else return `loading..`;
};

const PopularNFTList = (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      title="List of Popular NFTs"
      perPage={20}
      pagination={<PopularNFTPagination />}
      bulkActionButtons={false}
    >
      <Datagrid rowClick={PopularNFTRowClick}>
        <TextField source="id" sortable={false} />
        <TextField source="nftId.title" sortable={false} label="NFT Title" />
        {/* <ImageField source="nftId.image.compressed" sortable={false} label="NFT Image" className={classes.imgContainer} /> */}
        <CustomRowLayout />
        <TextField source="ranking" sortable={false} />
        {/* <BooleanField source="isActive" sortable={false} label="Active" /> */}
        <PopularNFTShowButton source="View" />
      </Datagrid>
    </List>
  );
};

export default PopularNFTList;
