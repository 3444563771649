import * as React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  BooleanField,
  ImageField,
  TopToolbar,
  TabbedShowLayout,
  Tab,
  ArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "../backButton";

const useStyles = makeStyles({
  imgContainer: {
    "& img": {
      height: "150px",
      width: "150px",
      objectFit: "contain",
      borderRadius: "50%",
    },
  },
});

const UserShow = (props) => {
  const classes = useStyles();

  const UserActions = ({ basePath, data, resource }) => (
    <TopToolbar>
      <BackButton color="primary" style={{ marginLeft: "5rem" }} />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );

  return (
    <Show {...props} actions={<UserActions />}>
      <SimpleShowLayout>
        <TabbedShowLayout>
          <Tab label="Profile">
            <TextField source="id" sortable={false} />
            <ImageField
              source="profile"
              sortable={false}
              className={classes.imgContainer}
            />
            <TextField source="username" sortable={false} />
            <TextField source="name" sortable={false} />
            <TextField source="email" sortable={false} />
            <TextField source="bio" sortable={false} />
          </Tab>
          <Tab label="Categories">
            <ArrayField source="category">
              <SingleFieldList>
                <ChipField source="categoryName.en" />
              </SingleFieldList>
            </ArrayField>

            <ArrayField source="approvedCategories">
              <SingleFieldList source="approvedCategories">
                <ChipField source="categoryName.en" />
              </SingleFieldList>
            </ArrayField>
          </Tab>
          <Tab label="Role">
            <TextField source="role.id" label="Role" sortable={false} />
            <TextField
              source="role.roleName"
              label="Role Name"
              sortable={false}
            />
          </Tab>
          <Tab label="Wallet">
            <TextField source="walletAddress" sortable={false} />
          </Tab>
          <Tab label="NFT">
            <TextField source="nftCreated" sortable={false} />
          </Tab>
          <Tab label="Portfolio">
            <TextField source="portfolio.instagarm.username" sortable={false} />
            <TextField source="portfolio.instagarm.url" sortable={false} />
            <TextField source="portfolio.facebook.username" sortable={false} />
            <TextField source="portfolio.facebook.url" sortable={false} />
            <TextField source="portfolio.github.username" sortable={false} />
            <TextField source="portfolio.github.url" sortable={false} />
            <TextField source="portfolio.twitter.username" sortable={false} />
            <TextField source="portfolio.twitter.url" sortable={false} />
          </Tab>
          <Tab label="Other Details">
            <DateField source="createdAt" sortable={false} />
            <BooleanField source="isActive" sortable={false} />
            <BooleanField source="acceptedByAdmin" sortable={false} />
            <TextField source="status" sortable={false} />
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
